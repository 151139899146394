<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <filter-data :get-report="getDataList" />
      </b-card>
      <b-card no-body>
        <b-card-body class="text-right">
          <b-button
            :to="$route.path + '/add'"
            variant="primary"
          >
            <FeatherIcon icon="PlusIcon" />
            Ekle
          </b-button>
        </b-card-body>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
          >
            <template #cell(brand)="data">
              <div class="text-primary font-weight-bold">
                {{ data.item.brand }}
                <div class="text-warning">
                  <small>{{ data.item.department }}</small>
                </div>
              </div>
              <small>{{ data.item.location }}</small>
            </template>
            <template #cell(month)="data">
              <div class="text-center">
                <b-badge
                  variant="primary"
                >
                  <span v-if="data.item.month">
                    {{ months.find((e) => e.id == data.item.month).title }}
                  </span>
                  {{ data.item.year }}
                </b-badge>
              </div>
            </template>
            <template #cell(goals)="data">
              <div class="text-center">
                {{ data.item.goals? parseFloat(data.item.goals) : 0 }}
              </div>
            </template>
            <template #cell(score)="data">
              <div class="text-center">
                {{ data.item.score? parseFloat(data.item.score) : 0 }}
              </div>
            </template>
            <template #cell(ranking)="data">
              <div class="text-center">
                {{ data.item.ranking? parseFloat(data.item.ranking) : 0 }}
              </div>
            </template>
            <template #cell(control)="data">
              <b-button
                :to="$route.path + '/edit/' + data.item.id"
                variant="primary"
                size="sm"
                class="mr-1"
              >
                Güncelle
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                @click="removeItemData(data.item.id)"
              >
                Sil
              </b-button>
            </template>
          </b-table></b-card-text>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BCardText, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BBadge, BCardBody,
} from 'bootstrap-vue'
import FilterData from '@/views/External/Csi/Brands/FilterData.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BBadge,
    BCardBody,
    FilterData,
  },
  data() {
    return {
      filterData: {
        id_com_brand: null,
        id_com_department: null,
        year: null,
      },
      currentPage: 1,
      perPage: 25,
      search: '',
      fields: [
        {
          key: 'brand',
          label: 'Marka',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'month',
          label: 'Dönem',
          thStyle: { width: '250px', textAlign: 'center', verticalAlign: 'middle' },
        },
        {
          key: 'goals',
          label: 'HEDEF',
          thStyle: { width: '100px', textAlign: 'center', verticalAlign: 'middle' },
        },
        {
          key: 'score',
          label: 'SKOR',
          thStyle: { width: '100px', textAlign: 'center', verticalAlign: 'middle' },
        },
        {
          key: 'ranking',
          label: 'TÜRKİYE SIRALAMASI',
          thStyle: { width: '100px', textAlign: 'center', verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-200 text-nowrap text-right',
          tdClass: 'width-200 text-nowrap text-right',
        },
      ],
      months: [
        { id: 1, title: 'Ocak' },
        { id: 2, title: 'Şubat' },
        { id: 3, title: 'Mart' },
        { id: 4, title: 'Nisan' },
        { id: 5, title: 'Mayıs' },
        { id: 6, title: 'Haziran' },
        { id: 7, title: 'Temmuz' },
        { id: 8, title: 'Ağustos' },
        { id: 9, title: 'Eylül' },
        { id: 10, title: 'Ekim' },
        { id: 11, title: 'Kasım' },
        { id: 12, title: 'Aralık' },
      ],
      dataQuery: {
        select: [
          'com_csi.id AS id',
          'com_csi.year AS year',
          'com_csi.month AS month',
          'com_csi.goals AS goals',
          'com_csi.score AS score',
          'com_csi.ranking AS ranking',
          'com_department.title AS department',
          'com_location.title AS location',
          'com_brand.name AS brand',
        ],
        where: {
          'com_csi.id_com_user_person': null,
        },
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['csi/getCsiRows']
    },
    dataCount() {
      return this.$store.getters['csi/getCsiRowsCount']
    },
    saveData() {
      return this.$store.getters['csi/getCsiRowSaveStatus']
    },
    filterDataParams() {
      return this.$store.getters['csiBrandsReport/getFilterData']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
    saveData(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.getDataList()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      if (this.filterDataParams.id_com_brand) {
        this.dataQuery.where['com_csi.id_com_brand'] = this.filterDataParams.id_com_brand
      } else {
        delete this.dataQuery.where['com_csi.id_com_brand']
      }
      if (this.filterDataParams.id_com_location) {
        this.dataQuery.where['com_csi.id_com_location'] = this.filterDataParams.id_com_location
      } else {
        delete this.dataQuery.where['com_csi.id_com_location']
      }
      if (this.filterDataParams.id_com_department) {
        this.dataQuery.where['com_csi.id_com_department'] = this.filterDataParams.id_com_department
      } else {
        delete this.dataQuery.where['com_csi.id_com_department']
      }
      if (this.filterDataParams.year) {
        this.dataQuery.where['com_csi.year'] = this.filterDataParams.year
      } else {
        delete this.dataQuery.where['com_csi.year']
      }
      this.$store.dispatch('csi/csiRowsList', this.dataQuery)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    removeItemData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('csi/csiRowRemove', id)
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
